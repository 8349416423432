import React, { lazy, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/auth";
import RestaurantList from "../pages/RestaurantManagement";
import EditRestaurant from "../pages/VenueManagement/EditRestaurant";
import ViewRestaurant from "../pages/VenueManagement/ViewRestaurant";
import AddRestaurant from "../pages/RestaurantManagement/AddRestaurant";
import AddRestaurantAdmin from "../pages/VenueManagement/AddRestaurant";

const Login = lazy(() => import("../pages/Login"));

const PageLayout = lazy(() => import("../pages/Layout"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const UserManagement = lazy(() => import("../pages/UserManagement"));
const AddUser = lazy(() => import("../pages/UserManagement/AddUser"));
const EditUser = lazy(() => import("../pages/UserManagement/EditUser"));
const ViewUser = lazy(() => import("../pages/UserManagement/ViewUser"));
const EditTrack = lazy(() => import("../pages/UserManagement/EditTrack"));
const ViewTrack = lazy(() => import("../pages/UserManagement/ViewTrack"));
const InviteGoatGuide = lazy(() => import("../pages/UserManagement/InviteGoatGuide"));

const ManagePrivacyPolicy = lazy(() => import("../pages/CMSManagement/ManagePrivacy"));
const ManageTerms = lazy(() => import("../pages/CMSManagement/ManageTerms"));
const OnboardingManagement = lazy(() => import("../pages/CMSManagement/OnboardingManagement"));
const AddOnboarding = lazy(() => import("../pages/CMSManagement/AddOnboarding"));
const EditOnboarding = lazy(() => import("../pages/CMSManagement/EditOnboarding"));
const ViewOnboarding = lazy(() => import("../pages/CMSManagement/ViewOnboarding"));
const VenueManagement = lazy(() => import("../pages/VenueManagement"));
const AddVenue = lazy(() => import("../pages/VenueManagement/AddVenue"));
const EditVenue = lazy(() => import("../pages/VenueManagement/EditVenue"));
const ViewVenue = lazy(() => import("../pages/VenueManagement/ViewVenue"));
const CuisineManagement = lazy(() => import("../pages/CuisineManagement"));
const AddCuisine = lazy(() => import("../pages/CuisineManagement/AddCuisine"));
const EditCuisine = lazy(() => import("../pages/CuisineManagement/EditCuisine"));
const ViewCuisine = lazy(() => import("../pages/CuisineManagement/ViewCuisine"));
const SubitemAdd = lazy(() => import("../pages/CuisineManagement/AddSubitem"));
const SubitemEdit = lazy(() => import("../pages/CuisineManagement/EditSubitem"));


const TagManagement = lazy(() => import("../pages/TagManagement"));
const AddTag = lazy(() => import("../pages/TagManagement/AddTag"));
const EditTag = lazy(() => import("../pages/TagManagement/EditTag"));
const ViewTag = lazy(() => import("../pages/TagManagement/ViewTag"));
const HeatManagement = lazy(() => import("../pages/HeatManagement"));
const AddHeat = lazy(() => import("../pages/HeatManagement/AddHeat"));
const EditHeat = lazy(() => import("../pages/HeatManagement/EditHeat"));
const ViewHeat = lazy(() => import("../pages/HeatManagement/ViewHeat"));
const VenueRequestManagement = lazy(() => import("../pages/VenueRequestManagement/index.js"));
const ViewMenuRequest = lazy(() => import("../pages/VenueRequestManagement/VIewVenuRequest.js"));
const RestaurantManagement = lazy(() => import("../pages/RestaurantManagement/index"));
const EditRestaurantAdmin = lazy(() => import('../pages/RestaurantManagement/EditRestaurant'))
const ViewRestaurantAdmin = lazy(() => import('../pages/RestaurantManagement/ViewRestaurant'))
const ReportedRestaurants = lazy(() => import('../pages/RestaurantManagement/ReportedRestaurants'))
const ViewReportedRestaurants = lazy(() => import('../pages/RestaurantManagement/ViewReportedRestaurants'))

const ManageGoatPrivacyPolicy = lazy(() => import("../pages/GoatGuideManagement/ManagePrivacyGoat"));
const ManageGoatTerms = lazy(() => import("../pages/GoatGuideManagement/ManageTermsGoat"));


const NotificationManagement = lazy(() => import('../pages/NotificationManagement'))
const AddNotification = lazy(() => import('../pages/NotificationManagement/AddNotification'))
const EditNotification = lazy(() => import('../pages/NotificationManagement/EditNotification'))
const PaymentManagement = lazy(() => import('../pages/PaymentManagement'))
const ViewPayment = lazy(() => import('../pages/PaymentManagement/ViewPayment'))
const AdvertisingManagement = lazy(() => import('../pages/AdvertisingManagement'))
const ViewAdvertising = lazy(() => import('../pages/AdvertisingManagement/ViewAdvertising'))
const UpdateLevel = lazy(() => import('../pages/AdvertisingManagement/Update'))

const GoatGuideManagement = lazy(() => import('../pages/GoatGuideManagement'))
const AddGoatGuide = lazy(() => import('../pages/GoatGuideManagement/AddGoatGuide'))
const EditGoatGuide = lazy(() => import('../pages/GoatGuideManagement/EditGoatGuide'))
const ViewGoatGuide = lazy(() => import('../pages/GoatGuideManagement/ViewGoatGuide'))
const GuideRequest = lazy(() => import('../pages/GuideRequestManagement/index.js'))

const EasterEggManagement = lazy(() => import('../pages/CMSManagement/EasterEggManagement'))
const AddEasterEggManagement = lazy(() => import('../pages/CMSManagement/AddEasterLink'))
const EditEasterEggManagement = lazy(() => import('../pages/CMSManagement/EditEasterLink'))

const TutorialsManagement = lazy(() => import('../pages/CMSManagement/TutorialsManagement.js'))
const AddTutorialsManagement = lazy(() => import('../pages/CMSManagement/AddTutorials.js'))
const EditTutorialsManagement = lazy(() => import('../pages/CMSManagement/EditTutorials.js'))

const CreditManagement = lazy(() => import('../pages/CreditManagement/index'))
const AddCredit = lazy(() => import('../pages/CreditManagement/AddCredit'))
const EditCredit = lazy(() => import('../pages/CreditManagement/EditCredit'))

const DealRequestManagement = lazy(() => import('../pages/DealRequestManagement/index.js'))
const ViewDealRequestManagement = lazy(() => import('../pages/DealRequestManagement/VIewDealRequest.js'))

const CommunicationRequestManagement = lazy(() => import('../pages/CommunicationRequestManagement/index.js'))
const ViewCommunicationRequestManagement = lazy(() => import('../pages/CommunicationRequestManagement/ViewCommunicationRequest.js'))

const FranchiseManagement = lazy(() => import('../pages/FranchiseManagement/index.js'))
const AddFranchise = lazy(() => import('../pages/FranchiseManagement/AddFranchise.js'))
const EditFranchise = lazy(() => import('../pages/FranchiseManagement/EditFranchise'))

const EditProfileRequests = lazy(() => import('../pages/EditProfileRequests/index.js'))

const SuggestionManagement = lazy(() => import('../pages/SuggestionManagement/index.js'))


const Routing = () => {
  const [container, setContainer] = useState(null);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<AuthGuard><PageLayout setContainer={setContainer} container={container} /></AuthGuard>}>
        {/* <Route index path="/dashboard" element={<Dashboard />} /> */}
        <Route index path="/change-password" element={<ChangePassword />} />
        <Route index path="/privacy-policy" element={<ManagePrivacyPolicy />} />
        <Route index path="/terms-conditions" element={<ManageTerms />} />
        <Route index path="/user-management" element={<UserManagement />} />
        <Route index path="/user-management/add-user" element={<AddUser />} />
        <Route index path="/user-management/edit-user" element={<EditUser />} />
        <Route index path="/user-management/view-user" element={<ViewUser />} />
        <Route index path="/goat-guide-management/invite-goat-guide" element={<InviteGoatGuide />} />
        {/* <Route index path="/user-management/analytics" element={<Analytics />} />
        <Route index path="/user-management/edit-analytics" element={<EditAnalytics />} />
        <Route index path="/user-management/view-analytics" element={<ViewAnalytics />} /> */}


        <Route index path="/user-management/edit-track" element={<EditTrack />} />
        <Route index path="/user-management/view-track" element={<ViewTrack />} />
        <Route index path="/onboarding-management" element={<OnboardingManagement />} />
        <Route index path="/onboarding-management/add-onboarding" element={<AddOnboarding />} />
        <Route index path="/onboarding-management/edit-onboarding" element={<EditOnboarding />} />
        <Route index path="/onboarding-management/view-onboarding" element={<ViewOnboarding />} />
        <Route index path="/venue-management" element={<VenueManagement />} />
        <Route index path="/venue-management/add-venue" element={<AddVenue />} />
        <Route index path="/venue-management/edit-venue" element={<EditVenue />} />
        <Route index path="/venue-management/view-venue" element={<ViewVenue />} />
        <Route index path="/cuisine-management/main/:currentType" element={<CuisineManagement />} />
        <Route index path="/cuisine-management/:type" element={<AddCuisine />} />
        <Route index path="/cuisine-management/edit-cuisine/:currentType" element={<EditCuisine />} />
        <Route index path="/cuisine-management/view-cuisine" element={<ViewCuisine />} />
        {/* <Route index path="/cuisine-management/:add-subitem" element={<SubitemAdd />} />
        <Route index path="/cuisine-management/:edit-subitem" element={<SubitemEdit />} /> */}
        {/* <Route index path="/cuisine-management/subitem" element={<CuisineManagement />} /> */}
        <Route index path="/cuisine-management/add-subitem" element={<SubitemAdd />} />
        <Route index path="/cuisine-management/edit-subitem" element={<SubitemEdit />} />

        <Route index path="/tag-management" element={<TagManagement />} />
        <Route index path="/tag-management/add-tag" element={<AddTag />} />
        <Route index path="/tag-management/edit-tag" element={<EditTag />} />
        <Route index path="/tag-management/view-tag" element={<ViewTag />} />
        <Route index path="/heat-management" element={<HeatManagement />} />
        <Route index path="/heat-management/add-heat" element={<AddHeat />} />
        <Route index path="/heat-management/edit-heat" element={<EditHeat />} />
        <Route index path="/heat-management/view-heat" element={<ViewHeat />} />
        <Route index path="/restaurant-request-management" element={< VenueRequestManagement />} />
        <Route index path="/restaurant-request-management/view-restaurant-request" element={<ViewMenuRequest />} />
        <Route index path="/admin/restaurant" element={<RestaurantList />} />
        <Route index path="/admin/viewrestaurant" element={<ViewRestaurant />} />
        <Route index path="/admin/editrestaurant" element={<EditRestaurant />} />
        <Route index path="/admin/addrestaurant" element={<AddRestaurantAdmin />} />
        <Route index path="/addrestaurant" element={<AddRestaurant />} />
        <Route index path="/editrestaurant" element={<EditRestaurantAdmin />} />
        <Route index path="/viewrestaurant" element={<ViewRestaurantAdmin />} />
        <Route index path="/restaurants" element={<RestaurantManagement />} />
        <Route index path="/restaurants/report" element={<ReportedRestaurants />} />
        <Route index path="/restaurants/view-report" element={<ViewReportedRestaurants />} />


        <Route index path="/notification-management" element={<NotificationManagement />} />
        <Route index path="/notification-management/add" element={<AddNotification />} />
        <Route index path="/notification-management/edit" element={< AddNotification />} />
        <Route index path="/payment-management" element={<PaymentManagement />} />
        <Route index path="/payment-management/view" element={<ViewPayment />} />
        <Route index path="/advertising-management" element={<AdvertisingManagement />} />
        <Route index path="/advertising-management/view" element={<ViewAdvertising />} />
        <Route index path="/advertising-management/update" element={<UpdateLevel />} />

        <Route index path="/goat-guide-management" element={<GoatGuideManagement />} />
        <Route index path="/goat-guide-management/add" element={<AddGoatGuide />} />
        <Route index path="/goat-guide-management/edit/:id" element={<EditGoatGuide />} />
        <Route index path="/goat-guide-management/view/:id" element={<ViewGoatGuide />} />
        <Route index path="/goat-privacy-policy" element={<ManageGoatPrivacyPolicy />} />
        <Route index path="/goat-terms-conditions" element={<ManageGoatTerms />} />
        <Route index path="/guide-request" element={<GuideRequest />} />

        <Route index path="/easter-egg-management" element={<EasterEggManagement />} />
        <Route index path="/easter-egg-management/add-link" element={<AddEasterEggManagement />} />
        <Route index path="/easter-egg-management/edit-link" element={<EditEasterEggManagement />} />

        <Route index path="/tutorials-management" element={<TutorialsManagement />} />
        <Route index path="/tutorials-management/add-link" element={<AddTutorialsManagement />} />
        <Route index path="/tutorials-management/edit-link" element={<EditTutorialsManagement />} />

        <Route index path="/credit-management" element={<CreditManagement />} />
        <Route index path="/credit-management/add-credit" element={<AddCredit />} />
        <Route index path="/credit-management/edit-credit" element={<EditCredit />} />

        <Route index path="/deal-request-management" element={<DealRequestManagement />} />
        <Route index path="/view-deal" element={<ViewDealRequestManagement />} />

        <Route index path="/communication-request-management" element={<CommunicationRequestManagement />} />
        <Route index path="/view-communication" element={<ViewCommunicationRequestManagement />} />

        <Route index path="/franchise-management" element={<FranchiseManagement />} />
        <Route index path="/franchise-management/add-franchise" element={<AddFranchise />} />
        <Route index path="/franchise-management/edit-franchise" element={<EditFranchise />}></Route>


        <Route path="/edit-profile-requests" element={<EditProfileRequests />} />
        <Route path="/suggestions-management" element={<SuggestionManagement />} />
      </Route>

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routing;
